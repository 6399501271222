
<style lang="scss">
#all-user-shifts {
  .card {
    .card-body {
      padding: 1rem 1rem 0rem 1rem;
    }
  }
  .table {
    background-color: white !important;
  }
  table {
    width: 100%;
    height: 41px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 10px 30px 0px rgb(82 63 105 / 8%);
    background: white;
    tr {
      border: 0.5px solid;
      border-color: #c7c7ca;
      height: 35px;
    }

    thead {
      tr {
        td {
          padding: 2px;
          font-weight: 600;
          border: 1px solid #dedede;
        }
      }
    }

    tbody {
      tr {
        &:hover {
          background-color: #eeeeee;
        }
        &:last-child {
          border-bottom: 0.5px solid;
          border-color: #b5b5c3;
        }

        td {
          //  border: 0.5px solid
          cursor: pointer;
          border: 0.5px solid;
          border-color: #c7c7ca;

          .shift {
            height: 30px;
            line-height: 28px;
            cursor: pointer;
            padding: 2px;
            border-radius: 5px;
            margin-left: 5px;
            margin-right: 5px;

            span {
              font-weight: 500;
              font-size: 10px;
              color: white;
            }
          }

          .shift-default{
            border-left: 5px solid black;
          }

        

          span {
            i {
              font-size: 14px;

              &:hover {
                font-size: 16px;
              }
            }
          }

          h2 {
            a {
              color: #333;
              font-size: 14px;
            }
          }
          .table-avatar {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            white-space: nowrap;
            font-size: inherit;
            font-weight: 400;
            margin: 0;
            padding: 0;
            vertical-align: middle;
            .avatar {
              background-color: #aaa;
              border-radius: 50%;
              display: inline-block;
              font-weight: 500;
              height: 38px;
              line-height: 38px;
              margin: 0 10px 0 0;
              text-align: center;
              text-decoration: none;
              text-transform: uppercase;
              vertical-align: middle;
              width: 38px;
              position: relative;
              white-space: nowrap;
              & > img {
                border-radius: 50%;
                display: block;
                overflow: hidden;
                width: 100%;
              }
            }

            .avatar-xs {
              width: 30px;
              height: 30px;
            }
          }
        }
        table.table td h2,
        table.table td h2.table-avatar {
          font-size: inherit;
          font-weight: 400;
          margin: 0;
          padding: 0;
          vertical-align: middle;
        }
        table.table td h2 {
          display: inline-block;
        }
      }
    }

    .employee-profile {
      width: 20%;
      text-align: left;
      padding: 10px;
    }
    .indicator-ball {
      padding: 10px;
      text-align: left;
      margin-bottom: 10px;
      display: inline;

      .legn-indi {
        padding: 0 5px;
        display: inline-flex;
        .circle {
          margin: 5px;
          width: 40px;
          height: 40px;
          border-radius: 50px;
          cursor: pointer;
          text-align: center;
          &:hover{
            border: 3px solid #e8e8e8c7;

            .icon {
           
            width: 15px;
       
          }
          }
          .icon {
            fill: white;
            width: 20px;
            margin-top: 10px;
          }
        }
        span {
          padding: 5px;
          font-size: 12px;
          color: #616161;
          cursor: pointer;
          i {
            font-size: 12px;
            margin-right: 5px;
            color: #616161;
          }
        }
      }
    }
  }
}
.day-timeline {
  display: grid;
  text-align: center;
  .day-name {
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
  }
  .day-number {
  }
}
</style>

<template>
  <section id="all-user-shifts">
    <div class="card mb-5">
      <div class="card-body">
        <v-legend
          :title="'Assign Shift'"
          :description="'Here are all the employees at your company, including terminated ones.'"
        >
        
        </v-legend>
        <div class="row mb-5">
          
          <div class="col-lg-4">
            <select
              class="mb-3 mr-sm-3 mb-sm-0 custom-select"
              v-model="searchData.month"
              @change="fetchShiftList()"
            >
              <option
                v-for="(month, index) in getMonths"
                :key="index"
                :value="index + 1"
              >
                {{ month }}
              </option>
            </select>
          </div>
          <div class="col-lg-4">
            <select
              class="mb-3 mr-sm-3 mb-sm-0 custom-select"
              v-model="searchData.year"
              @change="fetchShiftList()"
            >
              <option value="">--Select Year--</option>
              <option
                v-for="(year, index) in getYears"
                :key="index"
                :value="year"
              >
                {{ year }}
              </option>
            </select>
          </div>

             <div class="col-lg-4">
              <router-link
                :to="{ name: 'assign.shift' }"
             
                class="btn mr-3 btn-success"
              >
                <i class="fa fa-plus"></i>
                Assign Shift
              </router-link>
            </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <table v-if="loader">
          <thead>
            <tr>
              <td colspan="100">
                <data-loader></data-loader>
                <p>Getting data..</p>
              </td>
            </tr>
          </thead>
        </table>
        <table v-if="!loader">
          <thead>
            <tr>
              <td class="employee-profile" rowspan="2">
                <span style="text-align: center">Employee</span>
              </td>
              <td
                :colspan="getAssignShiftList.date.length"
                style="text-align: left; padding-left: 10px"
              >
                Days
              </td>
            </tr>
            <tr>
              <td
                v-for="(date, index) in getAssignShiftList.date"
                :key="index"
                :style="'width:' + 80 / getAssignShiftList.date.length + '%'"
              >
                <div class="day-timeline">
                  <span class="day-number">{{ date.day }}</span>
                  <span class="day-name">{{ date.day_name }}</span>
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in getAssignShiftList.data" :key="index">
              <td class="employee-profile">
                <h2 class="table-avatar">
                  <a href="/orange/profile" class="avatar avatar-xs"
                    ><img
                      alt=""
                      :src="item.avatar"
                  /></a>
                  <a href="/orange/profile" class="">{{item.full_name}}</a>
                </h2>
              </td>
              <td v-for="(dItem, index) in item.plan" :key="index" :colspan="dItem.colSpan">
                <div class="shift " :style="'background:'+dItem.color_code" :class="dItem.isDefault?'shift-default':''"  v-b-tooltip
                           :title=" dItem.from_time+ '-'+dItem.to_time " >
                   
                  <span>{{dItem.shift_title}}</span>
                </div>
              </td>
             
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="33">
                <div class="indicator-ball">
                  <div class="legn-indi float-left">
                    <!-- <span v-for="(shiftTypeItem,index) in getShiftTypes" :key="index"   v-b-tooltip
                            title="view details"
                      ><i class="fa fa-square" :style="'color:' + shiftTypeItem.color_code"  ></i
                      >{{shiftTypeItem.title}}</span
                    > -->

                    <div
                      class="circle"
                      v-for="(shiftTypeItem, index) in getShiftTypes"
                      :key="index"
                      :style="'background-color:' + shiftTypeItem.color_code"
                      v-b-tooltip
                           :title="shiftTypeItem.title + '(' +shiftTypeItem.from_time+ '-'+shiftTypeItem.to_time + ')'"
                    >
                      <svg class="icon" viewBox="0 0 24 24">
                        <g>
                          <path
                            d="M8 18v2H2c-1.103 0-2-.897-2-2V5c0-1.103.897-2 2-2h2v2h1V0h2v3h7v2h1V0h2v3h1c1.103 0 2 .898 2 2v3H2v10h6zm9 6c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zm0-12c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zm3 6h-4v-5h2v3h2v2z"
                          ></path>
                        </g>
                      </svg>
                    </div>

                   
                  </div>
                </div>
          
               
              </td>
            </tr>
            <tr>
              <td colspan="33">
                  <p style="padding:5px;margin:0">
                      Note: Shift with black border is default shift, on date where shift haven't assigned to employee.
                    </p>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
// import AllAttendanceHeader from "@/components/attendance/all/attendanceheader";
import Legend from "@/includes/content/widgets/Legend";
import dataLoader from "@/includes/content/DataLoader";
import { mapGetters } from "vuex";
import {
  FETCH_CURRENT_YEAR_MONTH,
  FETCH_ASSIGN_SHIFT_LIST,
  FETCH_SHIFT_TYPES,
} from "@/core/services/store/actions.type";

// import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tWidth: 80 / 10,
      loader: false,
      searchData: {
        year: "",
        month: "",
        shift_id: "all",
      },
    };
  },

  computed: {
    ...mapGetters([
      "getAssignShiftList",
      "getMonths",
      "getYears",
      "getShiftTypes",
    ]),
  },
  components: {
    "v-legend": Legend,
    "data-loader": dataLoader,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Assign Shift" }]);
    this.$store.dispatch(FETCH_SHIFT_TYPES);
    this.$store.dispatch(FETCH_CURRENT_YEAR_MONTH).then((data) => {
      this.searchData.year = parseInt(data.year);
      this.searchData.month = parseInt(data.month);
      this.fetchShiftList();
    });
  },
  methods: {
    fetchShiftList() {
      this.loader = true;
      this.$store
        .dispatch(FETCH_ASSIGN_SHIFT_LIST, this.searchData)
        .then(() => {
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
  },
  filters: {
    doubleDigit(number) {
      return number > 9 ? number : "0" + number;
    },
  },
};
</script>
